import React from "react";
import { ElementsConsumer, CardNumberElement } from "@stripe/react-stripe-js";

import CardForm from "./CardForm";
import { purchaseProduct } from "../../../Services/User";
import { toast } from "react-toastify";

class CardCheckoutForm extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      showSuccess: true,
    };
  }

  handleSubmit = async (event) => {
    const promoCode = event?.target?.promo?.value;
    event.preventDefault();
    this.setState({ isLoading: true });

    const { stripe, elements } = this.props;
    if (!stripe || !elements) return;

    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card);

    const prepareData = {
      stripeToken: result.token.id,
      promoCode,
      productId: this.props.planData.id,
    };

    try {
      await purchaseProduct(this.props.token, prepareData)
      this.props.callBackHandleSubmit({ success: true })
    } catch(error) {
      toast.error("Something went wrong please contact to peacefully support.", { position: toast.POSITION.TOP_RIGHT });
      this.props.callBackHandleSubmit({ success: false })
    }
  };

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <CardForm loading={this.state.isLoading} />
        </form>
      </>
    );
  }
}

export default function InjectedCheckoutForm({
  planData,
  token,
  callBackHandleSubmit,
}) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CardCheckoutForm
          stripe={stripe}
          elements={elements}
          planData={planData}
          token={token}
          callBackHandleSubmit={callBackHandleSubmit}
        />
      )}
    </ElementsConsumer>
  );
}
