import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  IconButton
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { getSessionInfoStart } from "../Redux-Saga/Redux/account";
import ImgWelcome from "../Assets/Images/img_welcome.png";
import { useLocation } from "react-router-dom";
import { increaseBoardCounting, setShowIframe, setIsFrameLoading } from "../Redux-Saga/Redux/ui";

const IframLoading = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [endMsg, setEndMsg] = useState();
  const { isRedirecting, isFrameLoading, counting } = useSelector((state) => state.ui);
  const { token, operateEmail } = useSelector((state) => state.account);

  useEffect(() => {
    dispatch(setIsFrameLoading(true));
    // eslint-disable-next-line
  }, [counting, operateEmail]);

  useEffect(() => {
    const messageHandler = (data) => {
      switch (data.data) {
        case "loading":
          dispatch(setIsFrameLoading(true));
          break;
        case "loading-end":
          dispatch(setIsFrameLoading(false))
          break;
        case "account-refetch":
          dispatch(getSessionInfoStart(token));
          break;
        case "show-iframe":
          dispatch(setShowIframe(true));
          break;
        case "hide-iframe":
          dispatch(setShowIframe(false));
          break;
        case "scroll-top":
          const element = document.getElementById("board");
          element.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          break;
        default:
      }

      if (typeof data?.data === 'string' && data?.data?.startsWith("End")) {
        const msgArray = data?.data?.split(':');
        if (msgArray.length >= 2) {
          setEndMsg(msgArray[1]);
          dispatch(setShowIframe(false));
          dispatch(increaseBoardCounting());
        }
      }
    }

    window.addEventListener("message", messageHandler);

    return () => window.removeEventListener("message", messageHandler);
    // eslint-disable-next-line
  }, []);

  if (location.pathname !== '/dashboard') return null;

  if (endMsg) {
    return (
      <Dialog
        maxWidth="xs"
        open={true}
        PaperProps={{ sx: { borderRadius: 8 } }}
        onClose={() => setEndMsg()}
      >
        <Box p={1} display="flex" justifyContent="flex-end">
          <IconButton onClick={() => setEndMsg()}>
            <CloseIcon style={{ fontSize: 32 }} />
          </IconButton>
        </Box>

        <Box display="flex" justifyContent="center" mb={2}>
          <Box
            width={100}
            height={100}
            display="flex"
            borderRadius="50px"
            alignItems="center"
            justifyContent="center"
            bgcolor="#D4ECFF"
          >
            <img src={ImgWelcome} alt="Welcome" />
          </Box>
        </Box>

        <Box mb={3}>
          <Typography variant="h2" color="primary" align="center">
            All Done!
          </Typography>
        </Box>

        <Box mb={6} px={6}>
          <Typography align="center">
            <Typography component="span">{`You finished the `}</Typography>
            <Typography fontWeight="700" component="span">{endMsg} section.</Typography>
            <Typography component="span">{` You may return to this section at anytime to modify your answers.`}</Typography>
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" pb={10}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ px: 15 }}
            onClick={() => setEndMsg()}
          >
            Okay
          </Button>
        </Box>
      </Dialog>
    )
  }

  if (isFrameLoading || isRedirecting) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="fixed"
        sx={{ top: 68, left: { xs: 0, md: 256} }}
        right="0"
        height="calc(100vh - 68px)"
        bgcolor="#fbfbfb80"
        zIndex="999"
      >
        <CircularProgress />
      </Box>
    );
  }

  return null;
}

export default IframLoading;
