import { LoadingButton } from "@mui/lab";
import { Box, Typography, Container } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import IframeResizer from "iframe-resizer-react";

import config from "../../Config/config";
import { getSessionInfoStart } from "../../Redux-Saga/Redux/account";
import { sendRequest } from "../../Services/User";
import { COLLABORATOR_KEY, POST_DEATH_SURVEY_ID } from "../../constants";
import PostDeathBoard from "./PostDeath";

const REQUEST_STATUS = {
  REJECTED: "Rejected",
  INVITED: "Invited",
  ACCEPTED: "Accepted",
  REQUESTED: "Requested full access"
};

const PLAN_IDS = [10, 9, 1, 2, 11, 4, 5, 6, 7, 34, 35, 36];

const OwnerBoard = ({ owner }) => {
  const { is_access: isAccess } = owner;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { counting, isFrameLoading, isShowIframe } = useSelector((state) => state.ui);
  const { token, sessionInfo } = useSelector((state) => state.account);

  const userId = sessionInfo?.currentUser?.id;
  const currentName = sessionInfo?.currentUser?.first_name;

  const apiUrl = config.apiHost;

  const showRequestBanner = isAccess === REQUEST_STATUS.INVITED || isAccess === REQUEST_STATUS.REJECTED;
  const showWaitingBanner = isAccess === REQUEST_STATUS.REQUESTED;

  const surveyUrl = useMemo(() => {
    const availableSectionIds = owner?.plan_ids?.split(',') || [];
    const disabledIds = PLAN_IDS.filter(it => !availableSectionIds.includes(String(it)));
    const completedIds = owner?.plan_completed_ids?.split(',')?.filter(it => !!it)?.map(it => it.replace('_', '')) || [];

    let link = `https://peacefully.qualtrics.com/jfe/form/${owner.survey_id}?fname=${owner?.first_name}&lname=${owner?.last_name}&email=${encodeURIComponent(owner?.email)}&contact_email=${encodeURIComponent(owner?.email)}&origin=${apiUrl}&CONTACT_ID=${owner.qualtrics_user_id}&accountType=guest`;
    if (isAccess !== REQUEST_STATUS.ACCEPTED) link += `&disabledIds=${disabledIds.toString()}`;
    
    // Add completed ids
    link += `&completedIds=${completedIds.toString()}`;

    // handler_name is current user's name, who is handling owner's dashboard
    link += `&handler_name=${currentName}`;

    // description 1
    const description1 = `Welcome to ${owner.first_name} ${owner.last_name}'s end-of-life plan.`;
    link += `&dashboard_description1=${encodeURIComponent(description1)}`;

    // description 2
    const description2 = `Here is where ${owner.first_name} ${owner.last_name} has recorded everything you would need.`;
    link += `&dashboard_description2=${encodeURIComponent(description2)}`;

    // description 3
    const description3 = `You have a limited view until the time comes when you need to access their information. If there is a life event and you need access to their plan, please submit a request.`;
    link += `&dashboard_description3=${encodeURIComponent(description3)}`

    return link;
    // eslint-disable-next-line
  }, [owner, isAccess])

  const handleRequestAccess = async () => {
    const { email, owner_id, qualtrics_user_id: qualtricsUserId } = owner;
    const data = {
      qualtricsUserId,
      ownerEmail: email,
      owner_id,
      deputy_id: userId,
    };

    setLoading(true);

    try {
      await sendRequest({ path: "/auth/hrAccessRequest", method: "post", payload: data });
      dispatch(getSessionInfoStart(token));
      setLoading(false);
    } catch (err) {
      dispatch(getSessionInfoStart(token));
      setLoading(false);

      const { data } = err?.response || {};
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const isCollaboratorBoard = useMemo(() => {
    return owner.post_death_ids === COLLABORATOR_KEY
  }, [owner])

  const isPostDeathSurvey = useMemo(() => {
    return owner?.survey_id === POST_DEATH_SURVEY_ID
  }, [owner])

  return (
    <>
      {showRequestBanner && !isFrameLoading && !isCollaboratorBoard && (
        <Box py={1} bgcolor="success.light" display="flex" alignItems="center" justifyContent="space-between" px={2}>
          <Typography color="#fff">
            Request access to all information
          </Typography>

          <LoadingButton
            sx={{ px: 2 }}
            variant="outlined"
            size="small"
            loading={loading}
            onClick={handleRequestAccess}
          >
            Report a Life Event
          </LoadingButton>
        </Box>
      )}

      {showWaitingBanner && !isFrameLoading && (
        <Box bgcolor="grey.300" py={2} px={4}>
          <Typography variant="h4" sx={{ mb: 1 }}>
            Request Sent - Waiting for Approval
          </Typography>

          <Typography>
            <Typography component="span">
              {`You have marked a life event and requested access to `}
            </Typography>

            <Typography component="b">
              {`${owner.first_name} ${owner.last_name} `}
            </Typography>

            <Typography component="span">{`full plan. `}</Typography>

            <Typography component="span">
              Peacefully is verifying this information over a 48 hour
              period. If confirmed, you will receive an email notification
              and be able to view the full Dashboard below after the 48
              hour period. You can continue to use the same log-in
              credentials.
            </Typography>
          </Typography>

          <Typography>Thank you.</Typography>
        </Box>
      )}
      <Container>
        {isCollaboratorBoard && isPostDeathSurvey && <PostDeathBoard owner={owner} />}

        <IframeResizer
          key={counting}
          title="Registration"
          id="qualtricsId"
          src={surveyUrl}
          style={{ width: "1px", minWidth: "100%", opacity: isShowIframe ? 1 : 0, visibility: isShowIframe ? 'visible' : 'hidden' }}
          checkOrigin={false}
          heightCalculationMethod="taggedElement"
        />
      </Container>
    </>
  );
}

export default OwnerBoard;
