import IframeResizer from "iframe-resizer-react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import config from "../../Config/config";
import SubscriptionReminder from "./SubscriptionReminder";
import { PLAN_SURVEY_ID, POST_DEATH_SURVEY_ID } from "../../constants";
import PostDeathBoard from "./PostDeath";
import { Container } from "@mui/material";

const MainBoard = () => {
  const apiUrl = config.apiHost;
  const { counting, isShowIframe } = useSelector((state) => state.ui);

  const { sessionInfo = {} } = useSelector((state) => state.account);
  const { currentUser = {}, embeddedData } = sessionInfo;
  
  const {
    qualtrics_user_id: qualtricsUserId,
    first_name: firstName,
    last_name: lastName,
    email,
    mobile,
    survey_id: userSurveyId,
    products = [],
    id: userId,
    isPromoted
  } = currentUser;
  

  const isLockLegal = useMemo(() => {
    if (isPromoted) return false;
    const planningProducts = products.filter((it) => it.category === 'planning');
    const postDeathProducts = products.filter((it) => it.category === 'post_death');

    if (userSurveyId === PLAN_SURVEY_ID) return planningProducts?.length === 0;
    if (userSurveyId === POST_DEATH_SURVEY_ID) return postDeathProducts?.length === 0;

    return true;
  }, [products, isPromoted, userSurveyId]);

  const surveyUrl = useMemo(
    () => {
      const encodedEmail = encodeURIComponent(email);
      let link;

      if (!userSurveyId) {
        link = `https://peacefully.qualtrics.com/jfe/form/SV_2mjsxiBf66rWOKG?fname=${firstName}&lname=${lastName}&email=${encodedEmail}&contact_mobile=${mobile}&user_id=${userId}&CONTACT_ID=${qualtricsUserId}&origin=${apiUrl}`;
      } else if (embeddedData.fname_pass && embeddedData.lname_pass && embeddedData.email_pass) {
        link = `https://peacefully.qualtrics.com/jfe/form/${PLAN_SURVEY_ID}?fname=${embeddedData.fname_pass}&lname=${embeddedData.lname_pass}&email=${encodedEmail}&contact_email=${encodedEmail}&origin=${apiUrl}&CONTACT_ID=${qualtricsUserId}&isLockLegal=${isLockLegal}`;
        
        // handler_name is current user's name, who is handling owner's dashboard
        link += `&handler_name=${firstName}`;

        // description 1
        const description1 = `Welcome to the comprehensive guide.`;
        link += `&dashboard_description1=${encodeURIComponent(description1)}`;

        // description 2
        const description2 = `Please complete the following sections as if you were the person this plan is for.`;
        link += `&dashboard_description2=${encodeURIComponent(description2)}`;

        // description 3
        const description3 = `All sections are optional, and you may fill them out based on your needs. All your data will be auto-saved and protected by bank-level security.`;
        link += `&dashboard_description3=${encodeURIComponent(description3)}`
      } else {
        link = `https://peacefully.qualtrics.com/jfe/form/${userSurveyId}?fname=${firstName}&lname=${lastName}&email=${encodedEmail}&contact_email=${encodedEmail}&CONTACT_ID=${qualtricsUserId}&origin=${apiUrl}&handler_name=${firstName}&isLockLegal=${isLockLegal}`;
        link += `&dashboard_description1=${encodeURIComponent(`Welcome to your comprehensive guide. All sections are optional, and you may fill them out based on your needs. All your data will be auto-saved and protected by bank-level security.`)}`;        
      }

      return link;
    },
    [
      userSurveyId,
      email,
      qualtricsUserId,
      firstName,
      lastName,
      apiUrl,
      mobile,
      userId,
      embeddedData,
      isLockLegal,
    ]
  );

  const isPostDeathSurvey = useMemo(() => {
    return userSurveyId ===  POST_DEATH_SURVEY_ID
  }, [userSurveyId]);

  return (
    <>
      {!userSurveyId && <SubscriptionReminder />}

      <Container>
        {isPostDeathSurvey && <PostDeathBoard />}

        <IframeResizer
          key={counting}
          title="Registration"
          id="qualtricsId"
          src={surveyUrl}
          style={{ width: "1px", minWidth: "100%", opacity: isShowIframe ? 1 : 0, visibility: isShowIframe ? 'visible' : 'hidden' }}
          checkOrigin={false}
          heightCalculationMethod="taggedElement"
        />
      </Container>
    </>
  );
}

export default MainBoard;
