import React, { useEffect, useMemo, useState } from "react";
import PrivateLayout from "../../Layouts/PrivateLayout";
import { useSelector } from "react-redux";
import { getUserDetails, getQDocs, userDeleteDoc } from "../../Services/User";
import FileUploader from "../../Components/FileUploader";
import { ConfirmDeleteModal } from "../../Components/modals";
import { userUploadDoc } from "../../Services/User";
import { toast } from "react-toastify";
import {
  Box,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import SuccessModal from "../../Components/modals/Success";

const DOCUMENT_TYPE_LIST = [
  { key: "dpoa_sign_url", label: "DPOA" },
  { key: "hcpoa_sign_url", label: "HCPOA" },
  {
    key: "live_willing_sign_url",
    label: "WILLING SIGN",
  },
  {
    key: "will_sign_url",
    label: "WILL SIGN",
  },
  {
    key: "will_trust_sign_url",
    label: "WILL TRUST SIGN",
  },
];

const Documents = () => {
  const account = useSelector((state) => state.account);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [fileList, setFileList] = useState([]);
  const [qDocs, setQDocs] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [docType, setDocType] = useState("dpoa_sign_url");
  const [activeDoc, setActiveDoc] = useState(null);
  
  const handleLoadQDocs = async () => {
    try {
      const result = await getQDocs(account.token);
      const files = result?.data?.data?.map((it) => {
        const nameArr = it.url.split('.');
        const type = nameArr[nameArr.length - 1];
        return { ...it, type: type.toUpperCase() };
      }) || [];
      setQDocs(files);
    } catch (error) {
      console.log("failed to load qualtrics docs")
    }
  }
  
  const handleLoadUserData = async () => {
    try {
      const result = await getUserDetails(account?.user?.id);
      setUserData(result?.data?.data);
    } catch (error) {
      console.log("error load user data ---", error);
    }
  };

  useEffect(() => {
    if (account?.user) {
      handleLoadUserData();
      handleLoadQDocs();
    }
    // eslint-disable-next-line
  }, [account]);

  const handleUpload = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", fileList[0]);
      formData.append("docType", docType);

      const result = await userUploadDoc(account.token, formData);
      if (result?.data?.data?.success) {
        console.log("resulet ---", result);
        toast.success("Document is uploaded successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        const key = result?.data?.data?.key;
        const url = result?.data?.data?.url;
        setUserData({
          ...userData,
          [key]: url,
        });
        setFileList([]);
      }
    } catch (error) {}

    setLoading(false);
  };

  const handleFile = (evt) => {
    setFileList(evt.target.files);
  };

  const handleDeleteDoc = async () => {
    try {
      setDeleteOpen(false);
      const result = await userDeleteDoc(account.token, activeDoc.key);
      if (result?.data?.data?.success) {
        setUserData({
          ...userData,
          [activeDoc.key]: null,
        });
      }
      setDeleteSuccess(true);
    } catch (error) {
      console.log("error ---", error);
    }
  };

  const docData = useMemo(() => {
    const data = [];
    if (userData?.dpoa_sign_url) {
      const nameArr = userData.dpoa_sign_url.split('.');
      const type = nameArr[nameArr.length - 1];

      data.push({
        fileName: "DPOA",
        type: type.toUpperCase(),
        downloadLink: userData?.dpoa_sign_url,
        key: "dpoa_sign_url",
      });
    }
    if (userData?.hcpoa_sign_url) {
      const nameArr = userData.hcpoa_sign_url.split('.');
      const type = nameArr[nameArr.length - 1];
      
      data.push({
        fileName: "HCPOA",
        type: type.toUpperCase(),
        downloadLink: userData?.hcpoa_sign_url,
        key: "hcpoa_sign_url",
      });
    }
    if (userData?.live_willing_sign_url) {
      const nameArr = userData.live_willing_sign_url.split('.');
      const type = nameArr[nameArr.length - 1];
      
      data.push({
        fileName: "LIVING WILL",
        type: type.toUpperCase(),
        downloadLink: userData?.live_willing_sign_url,
        key: "live_willing_sign_url",
      });
    }
    if (userData?.will_sign_url) {
      const nameArr = userData.will_sign_url.split('.');
      const type = nameArr[nameArr.length - 1];
      
      data.push({
        fileName: "WILL SIGN URL",
        type: type.toUpperCase(),
        downloadLink: userData?.will_sign_url,
        key: "will_sign_url",
      });
    }
    if (userData?.will_trust_sign_url) {
      const nameArr = userData.will_trust_sign_url.split('.');
      const type = nameArr[nameArr.length - 1];

      data.push({
        fileName: "WILL WITH TRUST",
        type: type.toUpperCase(),
        downloadLink: userData?.will_trust_sign_url,
        key: "will_trust_sign_url",
      });
    }

    return data;
  }, [userData]);

  return (
    <PrivateLayout>
      <Box p={3}>
        <Box mb={4}>
          <Typography variant="h3">Documents</Typography>
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableCell>File Name</TableCell>
              <TableCell>File Type</TableCell>
              <TableCell>Added By</TableCell>
              <TableCell align="center">Download</TableCell>
              <TableCell align="center">Remove</TableCell>
            </TableHead>

            <TableBody>
              {docData.map((docItem, index) => (
                <TableRow key={index}>
                  <TableCell>{docItem?.fileName}</TableCell>
                  <TableCell>{docItem?.type}</TableCell>
                  <TableCell>{account?.user?.first_name} {account?.user?.last_name}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      component="a"
                      target="_blank"
                      href={docItem.downloadLink}
                    >
                      <DownloadIcon color="primary" />
                    </IconButton>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={() => {
                        setDeleteOpen(true);
                        setActiveDoc(docItem);
                      }}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              
              {qDocs.map((doc, index) => (
                <TableRow key={`q-${index}`}>
                  <TableCell>{doc.name}</TableCell>
                  <TableCell>{doc?.type}</TableCell>
                  <TableCell>{account?.user?.first_name} {account?.user?.last_name}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      component="a"
                      target="_blank"
                      href={doc.url}
                    >
                      <DownloadIcon color="primary" />
                    </IconButton>
                  </TableCell>
                  
                  <TableCell align="center">
                    <IconButton disabled>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box my={2}>
          <FileUploader fileList={fileList} onFile={handleFile} />
        </Box>

        <div className="documents-footer">
          <Select
            value={docType}
            sx={{ mr: "20px" }}
            onChange={(evt) => setDocType(evt.target.value)}
          >
            {DOCUMENT_TYPE_LIST.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                {item.label}
              </MenuItem>
            ))}
          </Select>

          <LoadingButton
            size="medium"
            variant="contained"
            color="primary"
            disabled={fileList.length === 0}
            onClick={handleUpload}
          >
            {loading ? `Uploading ...` : `Upload`}
          </LoadingButton>
        </div>

        <ConfirmDeleteModal
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          onOk={handleDeleteDoc}
        />

        <SuccessModal
          title="File Deleted"
          description={`Your file ${activeDoc?.fileName} has
          been successfully deleted.`}
          open={deleteSuccess}
          onClose={() => setDeleteSuccess(false)}
          onOk={() => setDeleteSuccess(false)}
        />
      </Box>
    </PrivateLayout>
  );
};

export default Documents;
