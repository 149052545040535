import axios from "axios";
import config from "../Config/config";

export const getUserSessionData = (accessToken) => {
  let api_url = `${config.apiHost}/auth/userSessionData`;
  return axios.get(api_url, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const addReminder = (accessToken, data) => {
  let api_url = `${config.apiHost}/reminder`;
  return axios.post(api_url, data, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const addCollaborator = (data) => {
  let api_url = `${config.apiHost}/auth/deputyUserCreate`;
  return axios.post(api_url, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const getEmbededData = (accessToken, data) => {
  let api_url = `${config.apiHost}/auth/getEmbededData`;
  return axios.post(api_url, data, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
}

export const listCollaboratorsByOwner = (data) => {
  let api_url = `${config.apiHost}/public/getDeputyStatus`;
  return axios.post(api_url, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export const updateReminder = (accessToken, data) => {
  let api_url = `${config.apiHost}/reminder/${data.id}`;
  return axios.put(api_url, data, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const removeReminder = (accessToken, data) => {
  let api_url = `${config.apiHost}/reminder/${data.id}`;
  return axios.delete(api_url, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const getUserDetails = (id) => {
  const api_url = `${config.apiHost}/auth/getUserDetails/${id}`;
  return axios.post(api_url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getUserSessionDataV1 = (accessToken, userId) => {
  let api_url = `${config.apiHost}/auth/userSessionData`;
  return axios.post(
    api_url,
    { userId },
    {
      headers: {
        "Content-Type": "application/json",
        access_token: accessToken,
      },
    }
  );
};

export const getLogin = (data) => {
  let api_url = `${config.apiHost}/auth/login`;
  let configOptions = {
    method: "post",
    url: api_url,
    responseType: "json",
    data: data,
  };
  return axios(configOptions);
};

export const postHSLog = data => {
  const api_url = `${config.apiHost}/auth/log-hs`;
  const configOptions = {
    method: "post",
    url: api_url,
    responseType: "json",
    data: data,
  };
  return axios(configOptions);
}

export const getUserRegister = (data) => {
  const api_url = `${config.apiHost}/auth/register`;
  const configOptions = {
    method: "post",
    url: api_url,
    responseType: "json",
    data: data,
  };
  return axios(configOptions);
};

export const createQualtricsUser = (payload) => {
  const url = `${config.apiHost}/auth/addQualtricsUser`;
  return axios({ method: "post", url, responseType: "json", data: payload });
};

export const deleteQualtricsUser = (id) => {
  const url = `${config.apiHost}/auth/removeQualtricsUser`;
  return axios({ method: "post", url, responseType: "json", data: { id } });
};

export const deleteCognitoUser = (data) => {
  const configOptions = {
    method: "post",
    url: `${config.apiHost}/public/deleteCognitoUser`,
    responseType: "json",
    data: data
  };
  return axios(configOptions);
};

export const sendRequest = ({ path, method, payload }) => {
  let api_url = `${config.apiHost}${path}`;
  let configOptions = {
    method,
    url: api_url,
    responseType: "json",
    data: payload,
  };
  return axios(configOptions);
};

export const userChangePassword = (accessToken, data) => {
  let api_url = `${config.apiHost}/auth/changePassword`;
  return axios.post(api_url, data, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const userForgotPassword = (data) => {
  let api_url = `${config.apiHost}/auth/forgotPassword`;
  let configOptions = {
    method: "post",
    url: api_url,
    responseType: "json",
    data: data,
  };
  return axios(configOptions);
};

export const userUpdateProfile = (accessToken, data) => {
  let api_url = `${config.apiHost}/auth/updateProfile`;
  return axios.post(api_url, data, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const userUpdateUserProfile = (accessToken, data) => {
  let api_url = `${config.apiHost}/auth/updateUserProfile`;
  return axios.post(api_url, data, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const userUpdateAvatar = (accessToken, data) => {
  let api_url = `${config.apiHost}/auth/updateAvatar`;
  return axios.post(api_url, data, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const userUploadDoc = (accessToken, data) => {
  let api_url = `${config.apiHost}/auth/uploadDoc`;
  return axios.post(api_url, data, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const userDeleteDoc = (accessToken, data) => {
  let api_url = `${config.apiHost}/auth/deleteDoc/${data}`;
  return axios.delete(api_url, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const getUserNotification = (accessToken, data) => {
  let api_url = `${config.apiHost}/auth/notifications`;
  return axios.get(api_url, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const deleteProfile = async (accessToken) => {
  const api_url = `${config.apiHost}/auth/deleteProfile`;
  return axios.get(api_url, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const createOwner = async (accessToken, data) => {
  const api_url = `${config.apiHost}/auth/create-owner-profile`;
  return axios.post(api_url, data, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const getUserPlan = (accessToken) => {
  let api_url = `${config.apiHost}/plan-list`;
  return axios.get(api_url, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const purchaseProduct = (accessToken, data) => {
  let api_url = `${config.apiHost}/plan/purchase`;
  return axios.post(api_url, data, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const sendEmail = (accessToken, data) => {
  let api_url = `${config.apiHost}/public/send-email`;
  return axios.post(api_url, data, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const getCsvFile = (accessToken) => {
  const apiUrl = `${config.apiHost}/auth/get-account-csv`;
  return axios.get(apiUrl, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};

export const sendContactUs = (data) => {
  let api_url = `${config.apiHost}/public/contact-us`;
  return axios.post(api_url, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getQDocs = (accessToken) => {
  const apiUrl = `${config.apiHost}/auth/qualtrics-docs`;
  return axios.get(apiUrl, {
    headers: {
      "Content-Type": "application/json",
      access_token: accessToken,
    },
  });
};
